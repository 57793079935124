import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="contact-info">
          <h3>Contact Us</h3>
          <p>
            <i className="far fa-envelope"></i> Email: kislay@skymetriks.com
          </p>
          <p>
            <i className="fas fa-phone"></i> Phone: +1 (240)825-7699
          </p>
        </div>
        <div className="footer-bottom">
          <p>© 2024 SkyMetriks. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer; 