import React from 'react';
import './Pages.css';

function Demo() {
  return (
    <div className="page-container">
      <div className="content-section">
        <h1>Software Demo</h1>
        <p className="demo-message">Our interactive demo is coming soon!</p>
      </div>
    </div>
  );
}

export default Demo; 