import React from 'react';
import './Pages.css';

function Careers() {
  return (
    <div className="page-container">
      <div className="content-section">
        <h1>Careers at SkyMetriks</h1>
        
        <section className="careers-section">
          <h2>Join Our Mission</h2>
          <p>Help us shape the future of space safety and exploration. We're always looking for talented individuals passionate about space technology and innovation.</p>

          <h3>How to Get Involved</h3>
          <p>While we're building our careers portal, you can reach out to us directly at:</p>
          <p className="contact-email">kislay@skymetriks.com</p>

          <div className="careers-info">
            <h3>What We Look For</h3>
            <ul>
              <li>Passion for space technology and innovation</li>
              <li>Strong problem-solving abilities</li>
              <li>Collaborative mindset</li>
              <li>Dedication to excellence</li>
            </ul>
          </div>

          <p className="careers-note">
            Note: We'll be regularly updating this page with new opportunities. 
            Check back often or reach out directly to learn about current openings.
          </p>
        </section>
      </div>
    </div>
  );
}

export default Careers; 