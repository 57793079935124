import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import LandingPage from './LandingPage';
import Customers from './pages/Customers';
import InvestorRelations from './pages/InvestorRelations';
import Careers from './pages/Careers';
import Demo from './pages/Demo';
import './App.css';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/investor-relations" element={<InvestorRelations />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/demo" element={<Demo />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
