import React from 'react';
import './LandingPage.css';

function LandingPage() {
  return (
    <div className="landing-page">
      <div className="content-section">
        <h1>SkyMetriks</h1>
        <h2>Safeguarding Space Operations with Advanced Space Situational Awareness</h2>
        
        <h2>The Future of Space Safety Starts Today</h2>
        <p>
          As Earth's orbit becomes increasingly crowded with satellites and space debris, 
          the risk of collisions poses a significant threat to the sustainability of space 
          activities. SkyMetriks is dedicated to ensuring the safety and longevity of space 
          missions by providing cutting-edge Space Situational Awareness (SSA) and space 
          traffic management solutions.
        </p>

        <h2>Our Mission</h2>
        <p>
          To enhance space safety by delivering accurate, real-time tracking and collision 
          avoidance services, empowering satellite operators to make informed decisions and 
          protect their valuable assets.
        </p>

        <h2>Why Choose SkyMetriks?</h2>
        <div className="feature-grid">
          <div className="feature-item">
            <h4>Real-Time Space Object Tracking</h4>
            <p> Comprehensive Monitoring: Track satellites, debris, and other space objects with unparalleled precision.</p>
            <p> Up-to-the-Minute Updates: Receive the latest data to stay ahead of potential risks.</p>
          </div>
          
          <div className="feature-item">
            <h4>Advanced Collision Avoidance</h4>
            <p> Predictive Analytics: Utilize sophisticated algorithms to forecast potential collisions.</p>
            <p> Automated Alerts: Get instant notifications with actionable insights to prevent incidents.</p>
          </div>
          
          <div className="feature-item">
            <h4>User-Friendly Platform</h4>
            <p> Intuitive Dashboards: Access critical information through customizable and easy-to-understand interfaces.</p>
            <p> 3D Visualization: Visualize space environments and object trajectories in three dimensions.</p>
          </div>
        </div>

        <h2>Benefits to Your Operations</h2>
        <div className="feature-grid">
          <div className="feature-item">
            <p> Protect Your Assets: Minimize the risk of collisions and extend the lifespan of your satellites.</p>
            <p> Cost Efficiency: Avoid costly damages and mission interruptions.</p>
          </div>
          <div className="feature-item">
            <p> Regulatory Compliance: Stay aligned with international space safety regulations and guidelines.</p>
            <p> Operational Excellence: Enhance decision-making with accurate and timely data.</p>
          </div>
        </div>

        <h2>Who Can Benefit?</h2>
        <p>
           Satellite Operators: Commercial and government entities managing spacecraft.
        </p>
        <p>
           Space Agencies: Organizations overseeing national and international space activities.
        </p>
        <p>
           Defense Departments: Military branches requiring secure and reliable space data.
        </p>
        <p>
           Insurance Providers: Companies offering satellite insurance needing risk assessments.
        </p>
        <p>
           Launch Service Providers: Entities responsible for sending payloads into space.
        </p>

        <h2>Our Technology</h2>
        <p>
           Orbit Determination Algorithms: Accurate calculation of object positions and velocities.
        </p>
        <p>
           Machine Learning Integration: Enhanced predictive capabilities for anomaly detection.
        </p>
        <p>
           Secure Cloud Infrastructure: Robust, scalable, and secure data management.
        </p>

        <h2>Get Started Today</h2>
        <p>
          Take the first step towards enhanced space operations safety. Contact us to schedule 
          a demo and discover how our SSA solutions can transform your space operations.
        </p>

        <h3>Empowering Safe and Sustainable Space Exploration</h3>
      </div>
    </div>
  );
}

export default LandingPage;
