import React from 'react';
import { Link } from 'react-router-dom';
import './Layout.css';
import logo from './logo_sat_dat.png';
import Footer from './components/Footer';

const Layout = ({ children }) => {
  return (
    <div className="layout-container">
      <header className="landing-header">
        <div className="logo-title-container">
          <Link to="/">
            <img src={logo} alt="Logo" className="header-logo" />
          </Link>
          <Link to="/" className="header-title-link"> 
            <h1 className="header-title">SkyMetriks</h1>
          </Link>
        </div>
        <nav className="header-nav">
          <Link to="/customers">Customers</Link>
          <Link to="/investor-relations">Investor Relations</Link>
          <Link to="/careers">Careers</Link>
          <Link to="/demo">Software Demo</Link>
        </nav>
      </header>
      <main>
        {children}
      </main>
      <Footer />
    </div>
  );
}

export default Layout;
