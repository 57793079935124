import React from 'react';
import './Pages.css';

function InvestorRelations() {
  return (
    <div className="page-container">
      <div className="content-section">
        <h1>Investor Relations</h1>
        
        <section className="investor-section">
          <h2>Connect with Our Leadership</h2>
          <p>Reach out to our founder & CEO, Kislay Parashar, to discuss investment opportunities and partnerships.</p>
          
          <div className="google-form-container">
            <iframe 
              src="https://docs.google.com/forms/d/e/1FAIpQLSerQAZePATK-cMoZqDl3eje61OC6JniVVRXm5kzZVws0xKgig/viewform?embedded=true"
              width="100%" 
              height="876px"
              frameBorder="0" 
              marginHeight="0" 
              marginWidth="0"
              title="Investor Relations Contact Form"
            >
              Loading...
            </iframe>
          </div>
        </section>
      </div>
    </div>
  );
}

export default InvestorRelations; 