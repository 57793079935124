import React from 'react';
import './Pages.css';

function Customers() {
  return (
    <div className="page-container">
      <div className="content-section">
        <h1>Our Customers</h1>
        
        <section className="customer-section">
          <h2>Government & International Space Agencies</h2>
          <div className="section-content">
            <p>SkyMetriks provides essential services for government and international space agencies:</p>
            <ul>
              <li>Real-time space object tracking and collision avoidance systems</li>
              <li>Customized monitoring solutions for national space assets</li>
              <li>Comprehensive space situational awareness data for policy making</li>
              <li>International collaboration tools for space traffic management</li>
              <li>Advanced analytics for space environment assessment</li>
            </ul>
          </div>
        </section>

        <section className="customer-section">
          <h2>Universities & Research Institutes</h2>
          <div className="section-content">
            <p>Empowering academic research and education in space science:</p>
            <ul>
              <li>Access to historical space object tracking data</li>
              <li>Research-oriented APIs for custom analysis</li>
              <li>Educational tools for astronomy and space science programs</li>
              <li>Collaboration platforms for international research projects</li>
              <li>Special academic licensing options</li>
            </ul>
          </div>
        </section>

        <section className="customer-section">
          <h2>Businesses & Individuals</h2>
          <div className="section-content">
            <p>Supporting commercial space operations and individual stakeholders:</p>
            <ul>
              <li>Satellite operator solutions for fleet management</li>
              <li>Launch planning and mission analysis tools</li>
              <li>Risk assessment services for space insurance</li>
              <li>Customizable alerts and reporting systems</li>
              <li>Consulting services for space ventures</li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Customers; 